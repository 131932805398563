import { api, toCamelCase, toSnackCase } from '@cfgtech/helpers'
import { captureException } from '@sentry/vue'
import slugify from 'slugify'
import { useOrder } from '~/composables/order/useOrder'
import { FormsApi } from '~/services/generated/client'
import { useHandleError } from '~/composables/error'
import { ErrorTypesEnum } from '~/composables/error/types'

export enum DocumentTypesEnum {
  orderForm = 'order_form',
  handoverDocument = 'handover_document',
}

export type DocumentType = DocumentTypesEnum
export type DocumentName = 'order_new' | 'handover'

export enum DocumentCategory {
  NewOrder = 'NEW_ORDER',
  Handover = 'HANDOVER',
}

const documentCategoryToType: Record<DocumentCategory, DocumentType> = {
  [DocumentCategory.NewOrder]: DocumentTypesEnum.orderForm,
  [DocumentCategory.Handover]: DocumentTypesEnum.handoverDocument,
}

export type Document = {
  id: number
  name: string
  category: DocumentCategory
  fileMetadata: Record<string, unknown>
}

export type GetFilePayload = {
  token: string
  category: DocumentCategory
}

export enum DetailFormRequestTypes {
  CREATE_DOCUMENT = 'CREATE_DOCUMENT',
  REFRESH_FILE_METADATA = 'REFRESH_FILE_METADATA',
  LIST_DOCUMENTS = 'LIST_DOCUMENTS',
  RETRIEVE_DOWNLOAD_URL = 'RETRIEVE_DOWNLOAD_URL',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
}

type DocumentState = {
  toUpload: File | null
  uploaded: Document | null
}

export function useDocuments() {
  const formApi = new FormsApi(useApiConfiguration())

  const { orderToken } = useOrder()
  const { createError } = useHandleError()

  /**
   * List of already uploaded documents.
   */
  const uploadedDocuments = useState<Document[]>('uploadedDocuments', () => [])
  const loading = useState('documentsLoading', () => 0)

  /**
   * List of files to upload.
   * Undefined means that file not needed to be uploaded.
   * Null means that file is not uploaded yet.
   * File means that file is uploaded.
   */
  const filesToUpload = useState<Record<DocumentTypesEnum, DocumentState | undefined>>(
    'filesToUpload',
    () => ({
      [DocumentTypesEnum.orderForm]: undefined,
      [DocumentTypesEnum.handoverDocument]: undefined,
    }),
  )

  async function getFiles(category: DocumentCategory) {
    setLoading(true)

    try {
      if (!orderToken.value) {
        throw new Error('Chybí token')
      }

      const { data } = await formApi.askDetailForm({
        token: orderToken.value,
        detailQuestion: {
          type: DetailFormRequestTypes.LIST_DOCUMENTS,
          data: { category },
        },
      });

      (data as Document[]).forEach((document) => {
        const targetCategory = filesToUpload.value[documentCategoryToType[document.category]]

        if (!targetCategory) {
          throw new Error(`UNKNOWN_DOCUMENT_TYPE, data: ${JSON.stringify(data)}}`)
        }

        targetCategory.uploaded = toCamelCase(document) as Document

        // update uploaded documents
        const documentIndex = uploadedDocuments.value.findIndex(uploadedDocument => uploadedDocument.category === document.category)
        if (documentIndex !== -1) {
          uploadedDocuments.value[documentIndex] = toCamelCase(document) as Document
        }
      })

      return data
    }
    catch (err) {
      captureException(err)
      return null
    }
    finally {
      setLoading(false)
    }
  }

  async function uploadFile(file: File, payload: {
    category: DocumentCategory
    name: DocumentTypesEnum
  }) {
    setLoading(true)

    try {
      await Promise.all(
        uploadedDocuments.value
          .filter(document => document?.category === payload.category)
          .map(({ id }) => formApi.askDetailForm({
            token: orderToken.value!,
            detailQuestion: {
              type: DetailFormRequestTypes.DELETE_DOCUMENT,
              data: { id },
            },
          })),
      )

      // Create new file in the system
      const { data: { upload_url: uploadUrl } } = await formApi.askDetailForm({
        token: orderToken.value!,
        detailQuestion: {
          type: DetailFormRequestTypes.CREATE_DOCUMENT,
          data: {
            name: file.name,
            category: payload.category,
          },
        },
      })

      // Upload file to the s3;
      await api.FilesApi.uploadFile(uploadUrl.url, {
        file,
        userId: 0,
      })

      // Refresh file metadata to update file in server from s3;
      await formApi.askDetailForm({
        token: orderToken.value!,
        detailQuestion: {
          type: DetailFormRequestTypes.REFRESH_FILE_METADATA,
          data: toSnackCase({
            id: uploadUrl.id,
            token: orderToken.value!,
            uploadNotification: true,
            documentType: payload.name === DocumentTypesEnum.handoverDocument ? 'handover_document' : null,
          }),
        },
      })

      await getFiles(payload.category)
    }
    catch (_) {
      throw new Error(createError(ErrorTypesEnum.documentUploadFailed))
    }
    finally {
      setLoading(false)
    }
  }

  async function getFileUrl(id: number) {
    try {
      if (!orderToken.value) {
        throw new Error('Chybí token')
      }

      const { data } = await formApi.askDetailForm({
        token: orderToken.value,
        detailQuestion: {
          type: DetailFormRequestTypes.RETRIEVE_DOWNLOAD_URL,
          data: { id },
        },
      })

      return (data as { download_url: { id: number, url: string } }).download_url.url
    }
    catch (err) {
      captureException(err)
      return null
    }
  }

  function setFileToUpload(file: File | null, documentType: DocumentType) {
    const targetFile = filesToUpload.value[documentType]

    if (!targetFile) {
      throw new Error('UNKNOWN_DOCUMENT_TYPE')
    }

    targetFile.toUpload = file
      ? new File([file], slugify(file.name, { trim: true, lower: true }), { type: file.type })
      : null
  }

  function setLoading(value: boolean) {
    if (value) {
      loading.value += 1
    }
    else {
      loading.value -= 1
    }
  }

  return {
    filesToUpload: readonly(filesToUpload),
    uploadedDocuments: readonly(uploadedDocuments),
    loading: computed(() => loading.value > 0),

    uploadFile,
    getFiles,
    setFileToUpload,
    setLoading,
    getFileUrl,

    async init() {
      const route = useRoute()

      const { document, token } = route.query as {
        document?: DocumentType
        token?: string
      }

      if (!token) {
        throw new Error(createError(JSON.stringify({ statusCode: 404, message: 'MISSING_TOKEN' })))
      }

      const defaultFile = { toUpload: null, uploaded: null }

      // If the document is not defined, we need to upload both files.
      if (!document) {
        filesToUpload.value[DocumentTypesEnum.handoverDocument] = { ...defaultFile }
        filesToUpload.value[DocumentTypesEnum.orderForm] = { ...defaultFile }
      }
      else {
        // If the document is defined, we need to upload only the file for that document.
        filesToUpload.value[document] = { ...defaultFile }
      }

      const documents = await Promise.all([
        filesToUpload.value[DocumentTypesEnum.handoverDocument] && getFiles(DocumentCategory.Handover),
        filesToUpload.value[DocumentTypesEnum.orderForm] && getFiles(DocumentCategory.NewOrder),
      ].filter(Boolean))

      uploadedDocuments.value = documents.flat(1) as Document[]

      return documents
    },

    /**
     * Will reset all files to upload to null.
     */
    reset() {
      Object.keys(filesToUpload.value).forEach((key) => {
        const targetFile = filesToUpload.value[key as DocumentTypesEnum]

        if (targetFile) {
          targetFile.toUpload = null
        }
      })
    },
  }
}
